import React from 'react';
import { injectIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { Section, Column } from './section';
import Button from './button';
import logo from '../assets/logo.svg';
import walletPeople from '../assets/wallet-people.svg';

const Container = styled.header`
  position: relative;

  &::after {
    background-color: rgba(0, 0, 0, .05);
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
  }
`;

const Title = styled.h1`
  font-weight: 200;
  margin: 0;

  em {
    font-weight: 700;
  }

  @media (max-width: 575px) {
    font-size: 1.75em;
  }
`;

const SubTitle = styled.h4`
  font-weight: 400;
  margin-bottom: 2em;
`;

const Logo = styled.img.attrs({ src: logo })`
  display: block;
  margin: 2em 0;
`;

const WalletPeople = styled.img.attrs({ src: walletPeople })`
  display: block;
  width: 100%;

  @media (max-width: 575px) {
    margin-top: -3em;
  }
`;

const Hero = ({ intl, onSignup }) => (
  <Container>
    <Section background="#FAFBFF">
      <Column>
        <Logo />
      </Column>
    </Section>
    <Section bottom="xs" background="linear-gradient(to bottom, #FAFBFF 80%, #E7EAF7)">
      <Column md={5} mdOffset={2} style={{ marginTop: '2em', marginBottom: '8em' }}>
        <Title><FormattedHTMLMessage id="hero.title" /></Title>
        <SubTitle>{intl.formatMessage({ id: 'hero.subTitle' })}</SubTitle>
        <Button onClick={onSignup}>{intl.formatMessage({ id: 'signup' })}</Button>
      </Column>
      <Column md={4}>
        <WalletPeople />
      </Column>
    </Section>
  </Container>
);

export default injectIntl(Hero);
