import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { Section, Column } from './section';
import Button from './button';

const Container = styled.section`
  padding: 8em 0;
`;

const Title = styled.h1`
  color: var(--accent-color);
  margin: 0;
  text-align: center;
`;

const Text = styled.p`
  margin-bottom: 2em;
`;

const Signup = ({ intl, onSignup }) => {
  return (
    <Container>
      <Title>{intl.formatMessage({ id: 'signup.title' })}</Title>
      <Section center="xs">
        <Column>
          <Text>{intl.formatMessage({ id: 'signup.text' })}</Text>
          <Button onClick={onSignup}>{intl.formatMessage({ id: 'signup' })}</Button>
        </Column>
      </Section>
    </Container>
  );
};

export default injectIntl(Signup);
