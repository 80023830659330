import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import styled, { keyframes } from 'styled-components';
import { Section, Column } from './section';
import Title from './section-title';
import assets from '../assets';
import Phone from './phone-wrapper';

const translate = keyframes`
  from { transform: translateX(0vw); }
  to { transform: translateX(-200vw); }
`;

const Container = styled.section`
  padding-top: 4em;
  position: relative;
`;

const BrandsContainer = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 17.5em;
`;

const TopRow = styled.div`
  animation: ${translate} 120s infinite linear;
  display: flex;
`;

const BottomRow = styled(TopRow)`
  img {
    transform: translateX(-50%);
  }
`;

const Brand = styled.img`
  height: 15vw;
  width: auto;

  @media (min-width: 320px) and (max-width: 460px) {
    height: 55vw;
  }

  @media (min-width: 460px) and (max-width: 575px) {
    height: 45vw;
  }

  @media (min-width: 575px) and (max-width: 800px) {
    height: 30vw;
  }

  @media (min-width: 1600px) {
    height: 10vw;
  }
`;

const PhoneSection = styled(Section)``;

const Brands = ({ intl }) => {
  const brands = assets[intl.locale].brands;
  const topRow = Object.keys(brands).slice(0, 10);
  const bottomRow = Object.keys(brands).slice(10);

  return (
    <Container>
      <Title
        supTitle={intl.formatMessage({ id: 'brands.supTitle' })}
        title={intl.formatMessage({ id: 'brands.title' })} />
      <BrandsContainer>
        <TopRow>
          {topRow.map(brand => (
            <Brand key={brand} src={brands[brand]} />
          ))}
          {topRow.slice(0, 5).map(brand => (
            <Brand key={brand} src={brands[brand]} />
          ))}
        </TopRow>
        <BottomRow>
          {bottomRow.map(brand => (
            <Brand key={brand} src={brands[brand]} />
          ))}
          {bottomRow.slice(0, 5).map(brand => (
            <Brand key={brand} src={brands[brand]} />
          ))}
        </BottomRow>
      </BrandsContainer>
      <PhoneSection center="xs" start="lg">
        <Column lg={3}>
          <Phone dark screen={assets[intl.locale].app} />
        </Column>
      </PhoneSection>
    </Container>
  );
};

export default injectIntl(Brands);
