import React, { useEffect, useState } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import styled, { keyframes } from 'styled-components';
import Button from './button';
import SEO from './seo';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

const Container = styled.div``;

const Spinner = styled.span`
  animation: ${spin} 2s infinite linear;
  border: .125em solid var(--primary-color);
  border-top-color: transparent;
  border-radius: 50%;
  display: block;
  height: 3em;
  margin: auto;
  width: 3em;
`;

const Close = styled.button`
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 2em;
  position: absolute;
  right: .5em;
  top: .25em;
  transform: rotate(45deg);
  z-index: 10;
`;

const Loading = styled.div`
  text-align: center;
`;

const ErrorMessage = styled.p`
  margin: 2em auto;
  max-width: 30em;
`;

const Modal = ({ intl, onRequestClose }) => {
  const campaign = intl.locale === 'en' ? 'MF89a42417f1' : 'MF64942f402b';
  const [ rhLoaded, setRhLoaded ] = useState(false);
  const [ showError, setShowError ] = useState(false);

  useEffect(() => {
    if (window && window.RH) {
      setRhLoaded(!!window.RH.loaded);
      setTimeout(() => setShowError(!window.RH.loaded), 5000);
    }
  });

  return (
    <Container>
      {rhLoaded && <Close onClick={onRequestClose}>+</Close>}
      <SEO title={intl.formatMessage({ id: 'signup' })}>
        <script type="text/javascript">{`
          !function(m,a,i,t,r,e){if(m.RH)return;r=m.RH={},r.uuid=t,r.loaded=0,r.base_url=i,r.queue=[],m.rht=function(){r.queue.push(arguments)};var e=a.getElementsByTagName('script')[0],c=a.createElement('script');c.async=!0,c.src=i+'/widget/'+t+'.js',e.parentNode.insertBefore(c,e)}(window,document,'https://app.referralhero.com','${campaign}');
        `}</script>
      </SEO>
      <div id="maitre-widget" />
      {!rhLoaded &&
        <Loading>
          <Spinner />
          {showError &&
            <>
              <ErrorMessage>{intl.formatMessage({ id: 'signup.blockerError' })}</ErrorMessage>
              <Button onClick={onRequestClose}>{intl.formatMessage({ id: 'close' })}</Button>
            </>
          }
        </Loading>
      }
    </Container>
  );
};

export default injectIntl(Modal);
