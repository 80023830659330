import React, { useState } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import Modal from 'react-modal';
import GlobalStyles from '../components/global-styles';
import SEO from '../components/seo';
import Hero from '../components/hero';
import HowItWorks from '../components/how-it-works';
import Brands from '../components/brands';
import Signup from '../components/signup';
import Footer from '../components/footer';
import SignupModal from '../components/signup-modal';

const Home = ({ intl }) => {
  const [ showModal, setShowModal ] = useState(false);
  const hidden = { display: 'none', pointerEvents: 'none' };
  const styles = !showModal ? {
    overlay: hidden,
    content: hidden
  } : undefined;

  return (
    <>
      <GlobalStyles />
      <SEO title={intl.formatMessage({ id: 'title' })} />
      <Hero onSignup={() => setShowModal(true)} />
      <HowItWorks />
      <Brands />
      <Signup onSignup={() => setShowModal(true)} />
      <Footer />
      <Modal
        style={styles}
        className="modal"
        overlayClassName="overlay"
        ariaHideApp={false}
        isOpen
        onRequestClose={() => setShowModal(false)}>
        <SignupModal onRequestClose={() => setShowModal(false)} />
      </Modal>
    </>
  );
};

export default injectIntl(Home);
