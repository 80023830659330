import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  background-color: white;
  border: 1px solid #e9e9f1;
  border-radius: .25em;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1em;
  position: relative;
  text-align: center;

  @media (max-width: 575px) {
    height: auto;
    margin-bottom: 3em;
    padding: 2em 1em 1em;
  }

  sup {
    color: var(--accent-color);
  }
`;

const Count = styled.span`
  align-items: center;
  background-color: var(--accent-color);
  border-radius: 50%;
  color: var(--text-color-inverted);
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  height: 3em;
  justify-content: center;
  margin: -2.5em 0 2em;
  width: 3em;

  @media (max-width: 575px) {
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 35%);
  }
`;

const Icon = styled.div`
  margin-bottom: 1em;

  img {
    width: 3em;
  }

  img + img {
    margin-left: .5em;
  }

  @media (max-width: 575px) {
    margin: 1em 0;
  }
`;

const Step = ({ children, count, icon }) => {
  return (
    <Container>
      <Count>{count}</Count>
      <Icon>{icon}</Icon>
      {children}
    </Container>
  );
};

export default Step;
