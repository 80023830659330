export default {
  es: {
    app: require('./app-es@2x.png'),
    brands: {
      arredo: require('./brands/arredo.jpg'),
      ayNotDead: require('./brands/ay-not-dead.jpg'),
      carrefour: require('./brands/carrefour.jpg'),
      cebra: require('./brands/cebra.jpg'),
      columbia: require('./brands/columbia.jpg'),
      desiderata: require('./brands/desiderata.jpg'),
      dexter: require('./brands/dexter.jpg'),
      movistar: require('./brands/movistar.jpg'),
      infinit: require('./brands/infinit.jpg'),
      mimo: require('./brands/mimo.jpg'),
      mundoDelJuguete: require('./brands/mundo-del-juguete.jpg'),
      prototype: require('./brands/prototype.jpg'),
      rapsodia: require('./brands/rapsodia.jpg'),
      mercadoLibre: require('./brands/mercado-libre.jpg'),
      sarkani: require('./brands/sarkani.jpg'),
      staples: require('./brands/staples.jpg'),
      swarovski: require('./brands/swarovski.jpg'),
      tommyHilfiger: require('./brands/tommy-hilfiger.jpg'),
      vitamina: require('./brands/vitamina.jpg'),
      telcel: require('./brands/telcel.jpg')
    }
  },
  en: {
    app: require('./app-en@2x.png'),
    brands: {
      cebra: require('./brands/cebra.jpg'),
      mundoDelJuguete: require('./brands/mundo-del-juguete.jpg'),
      starbucks: require('./brands/starbucks.jpg'),
      amazon: require('./brands/amazon.jpg'),
      tommyHilfiger: require('./brands/tommy-hilfiger.jpg'),
      walmart: require('./brands/walmart.jpg'),
      bestBuy: require('./brands/best-buy.jpg'),
      dexter: require('./brands/dexter.jpg'),
      herencia: require('./brands/herencia.jpg'),
      infinit: require('./brands/infinit.jpg'),
      mimo: require('./brands/mimo.jpg'),
      morph: require('./brands/morph.jpg'),
      columbia: require('./brands/columbia.jpg'),
      apple: require('./brands/apple.jpg'),
      staples: require('./brands/staples.jpg'),
      googlePlay: require('./brands/google-play-store.jpg'),
      uber: require('./brands/uber.jpg'),
      swarovski: require('./brands/swarovski.jpg'),
      vitamina: require('./brands/vitamina.jpg'),
      xl: require('./brands/xl.jpg')
    }
  }
};
