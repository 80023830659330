import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { Section, Column } from './section';
import logo from '../assets/logo-mono.svg';
import angelList from '../assets/angel-list.svg';
import facebook from '../assets/facebook.svg';
import linkedIn from '../assets/linkedin.svg';
import medium from '../assets/medium.svg';
import twitter from '../assets/twitter.svg';

const Container = styled.section`
  background-color: #F1F3F5;
  box-shadow: var(--shadow-inset);
  padding: 2em 0;
`;

const SocialLink = styled.a`
  outline: none;
  text-decoration: none;

  & + & {
    margin-left: 1em;
  }
`;

const SocialIcon = styled.img``;

const Links = styled.ul`
  line-height: 2;
  list-style: none;
  margin: 0 0 2em;
  padding: 0;

  a {
    color: #8282a1;
    text-decoration: none;

    &:hover {
      text-decoration-line: underline;
    }
  }
`;

const Title = styled.h4`
  color: var(--primary-color);
  font-weight: 200;
  margin: 0 0 1em;
  text-transform: uppercase;
`;

const Logo = styled.img.attrs({ src: logo })`
  display: block;
  height: 1.5em;
  margin: 1em 0 2em;
`;

const Footer = ({ intl }) => {
  return (
    <Container>
      <Section>
        <Column md={3}>
          <SocialLink href="https://www.facebook.com/bidalihq" rel="noopener noreferrer">
            <SocialIcon src={facebook} />
          </SocialLink>
          <SocialLink href="https://www.linkedin.com/company/bidali" rel="noopener noreferrer">
            <SocialIcon src={linkedIn} />
          </SocialLink>
          <SocialLink href="https://twitter.com/bidalihq" rel="noopener noreferrer">
            <SocialIcon src={twitter} />
          </SocialLink>
          <SocialLink href="https://angel.co/bidali" rel="noopener noreferrer">
            <SocialIcon src={angelList} />
          </SocialLink>
          <SocialLink href="https://medium.com/bidali" rel="noopener noreferrer">
            <SocialIcon src={medium} />
          </SocialLink>
          <Logo />
        </Column>
        <Column md={3}>
          <Links>
            <li>{intl.formatMessage({ id: 'footer.contact' })} <a href="mailto:hello@bidali.com">hello@bidali.com</a></li>
          </Links>
        </Column>
        <Column md={2} mdOffset={4}>
          <Title>{intl.formatMessage({ id: 'footer.policies' })}</Title>
          <Links>
            <li><a href="https://www.bidali.com/policies/terms/">{intl.formatMessage({ id: 'footer.terms' })}</a></li>
            <li><a href="https://www.bidali.com/policies/privacy/">{intl.formatMessage({ id: 'footer.privacy' })}</a></li>
            <li><a href="https://www.bidali.com/policies/security/">{intl.formatMessage({ id: 'footer.security' })}</a></li>
            <li><a href="https://www.bidali.com/policies/cookies/">{intl.formatMessage({ id: 'footer.cookies' })}</a></li>
          </Links>
        </Column>
      </Section>
    </Container>
  );
};

export default injectIntl(Footer);
