import { createGlobalStyle, keyframes } from 'styled-components';
import 'normalize.css/normalize.css';

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10em) scale(.75);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

export default createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  :root {
    --background-color: #FAFBFF;
    --text-color: #555565;
    --text-color-subtle: #AAAAB2;
    --text-color-inverted: #FAFBFF;
    --primary-color: #4B4DF1;
    --accent-color: #E4517F;
    --shadow: 0 .125em .25em rgba(0, 0, 0, .1), 0 .5em 1em rgba(75, 77, 241, .1);
    --shadow-hover: 0 .25em .5em rgba(0, 0, 0, .075), 0 .75em 1.5em rgba(75, 77, 241, .1);
    --shadow-inset: inset 0 .25em .125em -.125em rgba(0, 0, 0, .025), inset 0 .5em .5em -.25em rgba(75, 77, 241, .1);
  }

  body {
    color: var(--text-color);
    font-family:
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
    line-height: 1.5;
  }

  a {
    color: var(--primary-color);
  }

  .overlay {
    align-items: center;
    background-color: rgba(255, 255, 255, .85);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    @supports (backdrop-filter: blur(.5em)) {
      backdrop-filter: blur(.5em);
      background-color: rgba(255, 255, 255, .5);
    }
  }

  .modal {
    animation: ${scaleIn} 500ms cubic-bezier(.55, 0, .1, 1);
    outline: none;
    position: relative;
  }

  #mtr-optin-form {
    box-shadow: var(--shadow) !important;

    #mtr-form {
      border-top: none !important;
    }

    [type="text"], [type="email"] {
      background: #FAFAFA;
      border: 1px solid rgba(0, 0, 0, .025) !important;
      border-radius: .125em;
      font-size: .85em !important;
      padding: .75em 1em !important;

      &::placeholder {
        color: #CCC;
      }

      &:hover, &:focus {
        background: white;
        border-color: transparent !important;
        box-shadow: var(--shadow) !important;
      }
    }

    [type="checkbox"] {
      &:checked {
        background-color: var(--primary-color);
      }
    }

    button {
      background-color: var(--primary-color);
      border-radius: .25em;
      box-shadow: var(--shadow);
      font-size: 1em;
      font-weight: 400;
      text-transform: uppercase;

      &:hover {
        box-shadow: var(--shadow-hover);
      }
    }
  }

  #maitre-widget:not(:empty) + div {
    display: none;
  }
`;
