import React from 'react';
import styled from 'styled-components';
import { Section, Column } from './section';

const Title = styled.h1`
  color: var(--primary-color);
  margin: 0 auto 2em;
`;

const SupTitle = styled.h4`
  color: var(--text-color-subtle);
  font-weight: 400;
  margin: 0;
`;

const SectionTitle = ({ supTitle, title }) => {
  return (
    <Section center="xs">
      <Column>
        <SupTitle>{supTitle}</SupTitle>
        <Title>{title}</Title>
      </Column>
    </Section>
  );
};

export default SectionTitle;
