import React from 'react';
import { injectIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { Section, Column } from './section';
import Title from './section-title';
import Step from './step';
import download from '../assets/download.svg';
import cart from '../assets/cart.svg';
import BTC from '../assets/BTC.svg';
import EUR from '../assets/EUR.svg';
import USD from '../assets/USD.svg';

const Container = styled.section`
  margin: 8em auto;

  sup {
    color: var(--accent-color);
    margin-right: 0.1em;
  }

  small {
    color: var(--text-color-subtle);
    display: block;
    margin-top: 2em;
  }
`;

const Icon = styled.img``;

const HowItWorks = ({ intl }) => {
  return (
    <Container>
      <Title
        supTitle={intl.formatMessage({ id: 'howItWorks.supTitle' })}
        title={intl.formatHTMLMessage({ id: 'howItWorks.title' })} />
      <Section center="xs">
        <Column sm={4} md={3}>
          <Step count={1} icon={<Icon src={download} />}>
            {intl.formatMessage({ id: 'howItWorks.step1' })}
          </Step>
        </Column>
        <Column sm={4} md={3}>
          <Step count={2} icon={<Icon src={cart} />}>
            {intl.formatMessage({ id: 'howItWorks.step2' })}
          </Step>
        </Column>
        <Column sm={4} md={3}>
          <Step count={3} icon={
            <>
              <Icon src={EUR} />
              <Icon src={USD} />
              <Icon src={BTC} />
            </>
          }>
            <div>
              <FormattedHTMLMessage id="howItWorks.step3" />
              <small><sup>*</sup>{intl.formatMessage({ id: 'howItWorks.finePrint' })}</small>
            </div>
          </Step>
        </Column>
      </Section>
    </Container>
  );
};

export default injectIntl(HowItWorks);
