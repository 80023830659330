import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: var(--background-color);
  border-radius: 2em;
  box-shadow:
    0 .125em .125em rgba(0, 0, 0, .1),
    0 0 2em rgba(0, 0, 0, .05),
    .5em 1em 1em rgba(0, 0, 0, .05),
    inset -.25em -.25em .5em rgba(0, 0, 0, .1),
    inset 0 .5em 4em white,
    inset 0 -.5em .5em #F2F2F2;
  display: inline-block;
  padding: 4em .5em;
  position: relative;

  &::before, &::after {
    border: 1px solid #F2F2F2;
    content: '';
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  &::before {
    background-color: #F0F0F0;
    border-radius: .125em;
    box-shadow: inset 0 .0625em .0625em rgba(0, 0, 0, .05);
    height: .25em;
    top: 2em;
    width: 3em;
  }

  &::after {
    background: linear-gradient(to bottom, #F4F4F4, #FFF);
    bottom: .75em;
    border-color: #EEE;
    border-radius: 1.25em;
    box-shadow: 0 .0625em 0 white;
    height: 2.5em;
    width: 2.5em;
  }

  img {
    border: 1px solid #F2F2F2;
    border-radius: .25em;
    display: block;
    height: auto;
    max-width: 60vw;
    overflow: hidden;
    width: ${props => props.width || '17em'};
  }

  &.iphone-x {
    padding: .75em;

    &::before {
      background: linear-gradient(to bottom, #FCFCFC, #FFFFFF);
      border-top: none;
      border-radius: 0 0 1em 1em;
      box-shadow: none;
      height: 1.5em;
      top: .75em;
      width: 48%;
    }

    &::after {
      display: none;
    }

    img {
      border-radius: 1.5em;
      border-color: #EEE;
    }
  }

  &.dark {
    background-color: #38434F;
    box-shadow: 0 .125em .125em rgba(0, 0, 0, .1),
      0 0 2em rgba(0, 0, 0, .05),
      .5em 1em 1em rgba(0, 0, 0, .05),
      inset 0 -.5em .5em rgba(0, 0, 0, .1),
      inset 0 0 .5em rgba(0, 0, 0, .1),
      inset .5em .5em .5em rgba(255, 255, 255, .1);

    &::before {
      background: #38434F;
      border-color: darken(#38434F, 10%);
    }

    img {
      border-color: darken(#38434F, 10%);
    }
  }

  &.landscape {
    box-shadow: 0 .125em .125em rgba(0, 0, 0, .1),
      0 0 2em rgba(0, 0, 0, .05),
      0.5em -.5em 0.5em rgba(0, 0, 0, .05),
      inset -.25em .25em .5em rgba(0, 0, 0, .20),
      inset .5em 0 4em white,
      inset -.5em 0 .5em #F2F2F2;
    transform: rotate(90deg);
  }
`;

export default ({ screen, iPhoneX = true, landscape, dark, width }) => (
  <Container
    width={width}
    className={`
      ${iPhoneX ? 'iphone-x' : ''}
      ${landscape ? 'landscape' : ''}
      ${dark ? 'dark' : ''}
    `}>
    <img alt="Phone Screen" src={screen} />
  </Container>
);
